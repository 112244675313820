import Home from './pages/Home'
import Contact from './pages/Contact'
import Projects from './pages/Projects'
import SingleProject from './pages/SingleProject'

import PageManager from '../core/PageManager'

import resize from '../utils/resize'
import addBowserClasses from '../utils/bowserToClasses'
import pageTransition from '../components/pageTransition'
import smoothscroll from 'smoothscroll-polyfill';

import scrollama from '../controllers/scrollama'
import scrollshow from '../controllers/scrollshow'


class Main {
  constructor () {
    this.initGlobalComponents()
    this.createPageManager()
  }

  createPageManager () {
    this.pageManager = new PageManager(document.querySelector('.main'), '.app-container', {
      'contact': Contact,
      'asm': Projects,
      'bpg': Projects,
      'asm/*': SingleProject,
      'bpg/*': SingleProject,
      '*': Home
    })
  }

  initGlobalComponents () {
    addBowserClasses()
    smoothscroll.polyfill();
    window.__forceSmoothScrollPolyfill__ = true;

    resize.init()
    pageTransition.init()
  }
}

export default Main
