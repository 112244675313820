import Page from '../../core/Page'

// import Footer from '../../components/footer'
// import CtaMail from '../../components/ctaMail'
// import LinesDetail from '../../components/linesDetail'
// import AnimesUp from '../../components/animesUp'
import Header from '../../components/header'
import ProjectsList from '../../components/ProjectsList'
import ProjectsBanner from '../../components/ProjectsBanner'
import lazyload from '../../utils/lazyload'

class Projects extends Page {
  askShow (previousPage) {
    return new Promise((resolve, reject) => {
      this.show(() => {
        this.state.shown = true
        resolve()
      }, previousPage)
    })
  } 


  askHide (nextPage) {
    return new Promise((resolve, reject) => {
      this.hide(() => {
        this.state.hidden = true
        this.unmout()
        resolve()
      }, nextPage)
    })
  }

  show (callback, previousPage) {
    this.el.style.opacity = 1
    this.init()
    callback()
  }

  init () {
    lazyload.init()
    Header.init()
    ProjectsList.init()
    ProjectsBanner.init()
    // LinesDetail.init()
    // AnimesUp.init()
  }

  unmout () {
    lazyload.remove()
    Header.unmount()
    ProjectsList.unmount()
    ProjectsBanner.unmount()
    // LinesDetail.unmount()
    // AnimesUp.unmount()
  }
}

Projects.pname = 'Projects'

export default Projects
