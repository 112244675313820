class Page {
  constructor (el) {
    this.state = { shown: false, hidden: false }
    this.el = el
  }

  name () {
    return this.constructor.name
  }

  prepare (previousPage) {
    this.el.style.opacity = 0
  }

  askShow (previousPage) {
    return new Promise((resolve, reject) => {
      this.show(() => {
        this.state.shown = true
        resolve()
      }, previousPage)
    })
  }

  show (callback, previousPage) {
    this.el.style.opacity = 1
    callback()
  }

  askHide (nextPage) {
    return new Promise((resolve, reject) => {
      this.hide(() => {
        this.state.hidden = true
        resolve()
      }, nextPage)
    })
  }

  hide (callback, nextPage) {
    this.el.style.opacity = 0
    callback()
  }

  flush () {}

  resize () {}
}

export default Page
