import anime from 'animejs'
import config from '../config'
import store from '../store/store'
import signals from '../store/signals'

class Header {
  constructor (props) {
    if (!props.el) return

    this.el = props.el

    this.menuMobileIsOpen = false
    this.isHome = document.body.classList.contains('template-home')
    this.activeGrid = null

    this.initParams()
    this.bind()
    this.resize()
  }

  initParams () {
    this.anchorsLinks = [...this.el.querySelectorAll('.link-anchor')]
    this.gridLinks = [...this.el.querySelectorAll('.link-grid')]
    this.panelLinks = [...this.el.querySelectorAll('.link-panel')]
    this.realText = this.el.querySelector('.real-text')
    this.menuBurger = this.el.querySelector('.menu-burger')

    // check if bpgGridFilterReal was selected
    if (store.bpgGridFilterReal.get() !== 'common' && store.bpgGridFilterReal.get() !== 'none') this.updateHeaderReal(store.bpgGridFilterReal.get())
  }

  bind () {
    if (config.small) this.menuBurger.addEventListener('click', this.toggleMenuMobile)
    store.resize.listen(this.resize)

    // anchors
    this.anchorsLinks.forEach((link) => {
      link.addEventListener('click', this.onAnchorClick)
    })

    if (this.anchorsLinks.length > 0) {
      signals.startingSection2.listen(this.updateAnchorOnScroll)
    }

    // grids
    this.gridLinks.forEach((link) => {
      link.addEventListener('click', this.onGridLinkClick)
    })

    // panels
    this.panelLinks.forEach((link) => {
      link.addEventListener('click', this.onPanelLinkClick)
    })

    store.bpgGridFilterReal.listen(this.updateHeaderReal)

    signals.closeMenu.listen(this.toggleMenuMobile)
  }

  unbind () {
    this.menuBurger.removeEventListener('click', this.toggleMenuMobile)
    store.resize.unlisten(this.resize)

    this.anchorsLinks.forEach((link) => {
      link.removeEventListener('click', this.onAnchorClick)
    })

    if (this.anchorsLinks.length > 0) {
      signals.startingSection2.unlisten(this.updateAnchorOnScroll)
    }

    this.gridLinks.forEach((link) => {
      link.removeEventListener('click', this.onGridLinkClick)
    })

    this.panelLinks.forEach((link) => {
      link.removeEventListener('click', this.onPanelLinkClick)
    })

    store.bpgGridFilterReal.unlisten(this.updateHeaderReal)

    signals.closeMenu.unlisten(this.toggleMenuMobile)
  }

  toggleMenuMobile = () => {
    if (this.menuMobileIsOpen) {
      document.body.classList.remove('no-scroll')
      this.menuMobileIsOpen = false
      this.el.classList.remove('menu-opened')
    } else {
      document.body.classList.add('no-scroll')
      this.menuMobileIsOpen = true
      this.el.classList.add('menu-opened')
    }
  }

  onAnchorClick = (event) => {
    event.preventDefault()

    const href = event.target.getAttribute('href')
    const elementToScroll = document.querySelector(`${href}`)
    if (!elementToScroll) return

    window.scrollTo({ top: elementToScroll.offsetTop, behavior: 'smooth' })

    // this.anchorsLinks.forEach((link) => {
    //   link.closest('li').classList.remove('active')
    // })
    // event.target.closest('li').classList.add('active')

    if (config.small) this.toggleMenuMobile()
  }

  updateAnchorOnScroll = (response) => {
    // const id = response.element.getAttribute('id')
    // if (!id) return

    // this.anchorsLinks.forEach((link) => {
    //   link.closest('li').classList.remove('active')
    //   const href = link.getAttribute('href')
    //   if (href.indexOf(id) > -1) link.closest('li').classList.add('active')
    // })
  }

  onGridLinkClick = (event) => {
    event.preventDefault()

    let dataGrid = event.target.getAttribute('data-grid')

    if (this.activeGrid === dataGrid) dataGrid = 'common' // if click on already active one = desactivate
    this.activeGrid = dataGrid

    store.bpgGridFilterType.set(this.activeGrid)

    // set active menu item
    this.gridLinks.forEach((link) => {
      link.closest('li').classList.remove('active')
      const _dataGrid = link.getAttribute('data-grid')
      if (_dataGrid == this.activeGrid) link.closest('li').classList.add('active')
    })

    if (config.small) this.toggleMenuMobile()
  }

  forceResetGridLink = () => {
    this.activeGrid = 'none'
    store.bpgGridFilterType.set(this.activeGrid)

    this.gridLinks.forEach((link) => {
      link.closest('li').classList.remove('active')
    })
  }

  onPanelLinkClick = (event) => {
    event.preventDefault()

    let dataPanel = event.target.getAttribute('data-panel')
    let panel = document.querySelector(`[data-panel-banner=${dataPanel}]`)
    if (!panel) return
    panel.classList.add('visible')
  }

  updateHeaderReal = (real) => {
    if (!this.realText) return

    // remove pub or clip is there was one selected
    this.forceResetGridLink()

    if (real == 'common') {
      this.el.classList.remove('has-real')
      setTimeout(() => { this.realText.innerText = '' }, 300)
    } else {
      this.el.classList.add('has-real')
      setTimeout(() => { this.realText.innerText = real }, 300)
    }
  }

  resize () {

  }


  destroy () {
    this.unbind()
    this.activeGrid = null
    store.bpgGridFilterType.set('common')
  }
}

let headerDom
let headers = []

function init () {
  headerDom = [...document.querySelectorAll('.js-app-header')]

  headerDom.forEach((el) => {
    headers.push(new Header({ el: el }))
  })
}

function unmount () {
  headers.forEach((header) => {
    header.destroy()
  })
}

export default {
  init,
  unmount,
  Header,
}
