import store from '../store/store'
import anime from 'animejs'

class ProjectsList {
  constructor (props) {
    if (!props.el) return

    this.el = props.el
    this.activeProjects = []

    this.initParams()
    this.bind()
  }

  initParams () {
    this.allProjects = [...this.el.querySelectorAll('.amovible-project')]
    this.allProjectsParents = [...this.el.querySelectorAll('.amovible-project-parent')]
  }

  bind () {
    store.bpgGridFilterType.listen(this.updateListType)
    store.bpgGridFilterReal.listen(this.updateListReal)
  }

  unbind () {
    store.bpgGridFilterType.unlisten(this.updateListType)
    store.bpgGridFilterReal.unlisten(this.updateListReal)
  }

  updateListType = (type) => {
    if (type === 'none') return

    if (type === 'common') {
      this.activeProjects = this.allProjects
    }
    else {
      this.activeProjects = []

      this.allProjects.forEach((el) => {
        const elType = el.getAttribute('data-type')
        if (elType === type) this.activeProjects.push(el)
      })
    }

    anime({
      targets: this.el,
      opacity: 0,
      duration: 500,
      easing: 'easeInOutQuad',
      complete: () => {
        // remove old childs
        this.allProjectsParents.forEach((projectParent) => {
          const linkChild = projectParent.querySelector('a')
          if (linkChild) projectParent.removeChild(linkChild)
        })

        // set new childs
        this.allProjectsParents.forEach((projectParent, index) => {
          if (this.activeProjects[index]) {
            projectParent.appendChild(this.activeProjects[index])
          }
        })

        anime({
          targets: this.el,
          opacity: 1,
          duration: 800,
          easing: 'easeInOutQuad',
        })
      }
    })
  }

  updateListReal = (real) => {
    if (real === 'none') return

    if (real === 'common') {
      this.activeProjects = this.allProjects
    }
    else {
      this.activeProjects = []

      this.allProjects.forEach((el) => {
        const elreal = el.getAttribute('data-real')

        const elRealLower = elreal.toLowerCase().trim()
        const realLower = real.toLowerCase().trim()

        if (elRealLower == realLower || elRealLower.indexOf(realLower) > -1) this.activeProjects.push(el)
      })

      // if (this.activeProjects.length === 0) store.bpgGridFilterReal.set('common')
      if (this.activeProjects.length === 0) document.querySelector('.app-header')?.classList.add('real-desnt-exists')
      else document.querySelector('.app-header')?.classList.remove('real-desnt-exists')
    }

    anime({
      targets: this.el,
      opacity: 0,
      duration: 500,
      easing: 'easeInOutQuad',
      complete: () => {
        // remove old childs
        this.allProjectsParents.forEach((projectParent) => {
          const linkChild = projectParent.querySelector('a')
          if (linkChild) projectParent.removeChild(linkChild)
        })

        // set new childs
        this.allProjectsParents.forEach((projectParent, index) => {
          if (this.activeProjects[index]) {
            projectParent.appendChild(this.activeProjects[index])
          }
        })

        anime({
          targets: this.el,
          opacity: 1,
          duration: 800,
          easing: 'easeInOutQuad',
        })
      }
    })
  }

  destroy () {
    this.unbind()
  }
}

let projectsListDom
let projectsLists = []

function init () {
  projectsListDom = [...document.querySelectorAll('.js-projects-list')]

  projectsListDom.forEach((el) => {
    projectsLists.push(new ProjectsList({ el: el }))
  })
}

function unmount () {
  projectsLists.forEach((projectsList) => {
    projectsList.destroy()
  })
}


export default {
  init,
  unmount,
  ProjectsList,
}
