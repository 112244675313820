import 'intersection-observer';
import scrollama from 'scrollama';
import signals from '../store/signals'
import store from '../store/store'

class scrollController {
  constructor (props) {
    this.scroller
    this.scroller2

    this.resize = this.resize.bind(this)
    this.resetScrollama = this.resetScrollama.bind(this)

    this.initParams()
    setTimeout(() => {
      this.setUpScrollama()
    }, 800)
    this.bind()
  }

  initParams () {
    window.scrollTo(0,0)
    this.scroller = scrollama();
    this.scroller2 = scrollama();
  }

  bind () {
    store.resize.listen(this.resize)
    signals.resetScrollama.listen(this.resetScrollama)
  }

  setUpScrollama () {
    window.scrollTo(10,0)

    if (document.querySelector('.scroll-step')) {
      this.scroller
        .setup({
          step: '.scroll-step',
          progress: true,
          offset: 0.85,
          order: true,
        })
        .onStepEnter(response => {
          signals.startingSection.dispatch(response)
        })
        .onStepProgress(response => {
          signals.progressUpdate.dispatch(response.progress)
        })
        .onStepExit(response => {
          signals.endingSection.dispatch(response)
        });
    }

    if (document.querySelector('.scroll-step-2')) {
      this.scroller2
        .setup({
          step: '.scroll-step-2',
          progress: true,
          offset: 0.1,
          order: true,
        })
        .onStepEnter(response => {
          signals.startingSection2.dispatch(response)
        })
        .onStepProgress(response => {
          signals.progressUpdate2.dispatch(response.progress)
        })
        .onStepExit(response => {
          signals.endingSection2.dispatch(response)
        });
    }
  }

  resetScrollama () {
    this.scroller.destroy()
    this.scroller2.destroy()

    setTimeout(() => {
      this.setUpScrollama()
    }, 800)
  }

  resize () {
    this.scroller.resize()
    this.scroller2.resize()
  }

}

export default new scrollController()
