import store from '../store/store'
import signals from '../store/signals'
import anime from 'animejs'
import config from '../config'

class ProjectsBanner {
  constructor (props) {
    if (!props.el) return

    this.el = props.el
    this.activeReal = null

    this.initParams()
    this.bind()
  }

  initParams () {
    this.allLinks = [...this.el.querySelectorAll('.banner-link')]
    this.resetBtn = document.querySelector('.link-reset-grid')
    this.closeBtn = this.el.querySelector('button.close')

    if (store.bpgGridFilterReal.get() !== 'common' && store.bpgGridFilterReal.get() !== 'none') this.forceSelectRealOnLoad(store.bpgGridFilterReal.get())
  }

  bind () {
    this.allLinks.forEach((el) => {
      el.addEventListener('click', this.onLinkClick)
    })

    if (this.resetBtn) this.resetBtn.addEventListener('click', this.reset)
    if (this.closeBtn) this.closeBtn.addEventListener('click', this.close)
  }

  unbind () {
    this.allLinks.forEach((el) => {
      el.removeEventListener('click', this.onLinkClick)
    })

    if (this.resetBtn) this.resetBtn.removeEventListener('click', this.reset)
    if (this.closeBtn) this.closeBtn.removeEventListener('click', this.close)
  }

  onLinkClick = (event) => {
    const target = event.target.closest('.banner-link')
    let dataReal = target.getAttribute('data-real')

    if (this.activeReal == dataReal) dataReal = 'common'
    this.activeReal = dataReal

    this.allLinks.forEach((link) => {
      link.classList.remove('active')
      const _dataReal = link.getAttribute('data-real')
      if (_dataReal == this.activeReal) link.classList.add('active')
    })

    store.bpgGridFilterReal.set(dataReal)
    this.el.classList.remove('visible')

    if (config.small) signals.closeMenu.dispatch()
  }

  forceSelectRealOnLoad = (dataReal) => {
    this.activeReal = dataReal

    this.allLinks.forEach((link) => {
      link.classList.remove('active')
      const _dataReal = link.getAttribute('data-real')
      console.log('HERE')
      if (_dataReal == this.activeReal) link.classList.add('active')
    })

    store.bpgGridFilterReal.set('none')
    store.bpgGridFilterReal.set(dataReal)
    this.el.classList.remove('visible')
  }

  reset = () => {
    this.allLinks.forEach((link) => {
      link.classList.remove('active')
    })

    this.activeReal = null
    store.bpgGridFilterReal.set('common')
  }

  close = () => {
    this.el.classList.remove('visible')
  }

  destroy () {
    this.unbind()
    this.activeReal = null
    // store.bpgGridFilterReal.set('common')
  }
}

let projectsBannerDom
let projectsBanners = []

function init () {
  projectsBannerDom = [...document.querySelectorAll('.js-projects-banner')]

  projectsBannerDom.forEach((el) => {
    projectsBanners.push(new ProjectsBanner({ el: el }))
  })
}

function unmount () {
  projectsBanners.forEach((projectsBanner) => {
    projectsBanner.destroy()
  })
}


export default {
  init,
  unmount,
  ProjectsBanner,
}
